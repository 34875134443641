::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.sidebar_logo_invert {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.App-header {
  background-color: #000;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: absolute;
}

.App-btn1 {
  width: 40%;
  margin-top: .5%;
}

.App-btn2 {
  width: 40%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Login-input1 {
  background-color:#fff;
  height: 35px;
  width: 250px;
  font-weight: bold;
  border-width:4;
  border-color:#333333;
  margin-top:50px;
  color:#000;
  font-size: 14px;
}

.Login-input2 {
  background-color:#fff;
  height: 35px;
  width: 250px;
  font-weight: bold;
  border-width:4;
  border-color:#333333;
  color:#000;
  font-size: 14px;
}

.Sidebar-form {
  display: flex;
  width: 100%;
  justify-content: center;
}

.Sidebar-btn {
  width: 85%;
  height: 35px;
  background-color: #000;
  color: #cdcdcd;
}
.Sidebar-btn:hover { background-color: #cdcdcd; color: #000 }

.Sidebar-btn-sel {
  width: 85%;
  height: 35px;
  background-color: #cdcdcd;
  color: #000;
}

.Sidebar-close {
  width: 100%;
  height: 100%;
  background-color: #400000;
  color: #cdcdcd;
  border-radius: 10px;
}
.Sidebar-close:hover { background-color: #2e0101}

.ReactVirtualized__Table { 
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
} 
.ReactVirtualized__Table__headerColumn { 
  border-right: 1px solid #ddd;
}
.ReactVirtualized__Table__headerRow { 
  text-align: left;
  background-color: #333;
  color: white;
  border: 1px solid #ddd;
  font-size: 12px
} 
.ReactVirtualized__Table__row { 
  border: 1px solid #ddd;
  font-size: 12px
} 
.ReactVirtualized__Table__row:nth-child(2n) {
  background-color: #f5f5f5
}
.ReactVirtualized__Table__rowColumn { 
  border-right: 1px solid #ddd;
}


#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers tr {
  background-color: #fff;
  cursor: pointer;
}

#customers td, #customers th {
  border: 1px solid #ddd;
}

/*#customers tr:hover {background-color: #ddd;}*/

#customers th {

  text-align: left;
  background-color: #000;
  color: white;
}

.enterdealHead {
  height: 100px;
  background-color: #000;
  position: fixed;
  top: 0;
  width: 100%;
}

.enterdealSideNav {
  width: 100%;
  background-color: #000;
  position: relative;
  z-index:1;
}

.merchBundle {
  width: 300px;
  border-style: solid;
  border-width: 4px;
  border-color: rgb(1, 22, 48);
  height:310px;
  margin-top:20px;
  border-radius:10px;
  background-color:rgb(1, 22, 48);
  margin-left: -15px;
}

.dealTextCenter {
    text-align: center;
    font-size: 15px;
    color:#cdcdcd
}

.showTimesBundle {
  width: 325px;
  border-style: solid;
  border-width: 4px;
  border-color: rgb(1, 22, 48);
  height:590px;
  margin-top:20px;
  border-radius:10px;
  background-color:rgb(1, 22, 48)
}

.ticketScalingNotesInput {
  height:120px;
  width:620px;
  font-size: 14px;
  margin-left:110px;
  max-height: 120px;
  max-width: 620px;
  min-height: 120px;
  min-width: 620px;
}

.ticketScalingBundle {
  width: 220px;
  border-style: solid;
  border-width: 4px;
  border-color: rgb(1, 22, 48);
  min-height:300px;
  max-height: auto;
  margin-top:40px;
  margin-left:5px;
  border-radius:10px;
  background-color:rgb(1, 22, 48);
  box-shadow: 1px 1px 6px rgba(0,0,0,0.5);
}

.ticketScalingCalcBundle {
  width: 310px;
  border-style: solid;
  border-width: 4px;
  border-color: rgb(1, 22, 48);
  height:auto;
  margin-top:50px;
  margin-left:-80px;
  border-radius:10px;
  background-color:rgb(1, 22, 48);
  box-shadow: 1px 1px 6px rgba(0,0,0,0.5)
}

.ticketScalingCalcBundle_combined {
  width: 310px;
  border-style: solid;
  border-width: 4px;
  border-color: rgb(1, 22, 48);
  height:auto;
  margin-top:20px;
  margin-left:-80px;
  border-radius:10px;
  background-color:rgb(1, 22, 48);
  box-shadow: 1px 1px 6px rgba(0,0,0,0.5)
}

.dealTermsCalcBundle {
  width: 90%;
  border-style: solid;
  border-width: 4px;
  border-color: rgb(1, 22, 48);
  height:auto;
  margin-top:40px;
  border-radius:5px;
  background-color:rgb(1, 22, 48);
  box-shadow: 2px 2px 12px rgba(0,0,0,0.5);
}

.dealPointsText {
  height:8px;
  font-size: 14px;
  margin-top:15px;
  color:#000
}

.dealPointsInput {
  height:120px;
  width:540px;
  font-size: 14px;
  margin-top:10px;
  max-height: 120px;
  max-width: 540px;
  min-height: 120px;
  min-width: 540px;
}

.mode-s {
  position: fixed;
  z-index: 5;
  overflow-x: scroll;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.50);
}

.mode-s-tag {
  position: absolute;
  top: 8%;
  left: 6%;
  min-width: 1000px;
  width: 90%;
  height: auto;
  border-radius: 5px;
  border: 2px solid #333333;
}

.close {
  color: #ddd;
  float: right;
  margin-right: 20px;
  margin-top: 10px;
  font-size: 24px;
}
.close:hover {
  color: #fff;
  cursor: pointer;
}

.mode-t {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: scroll;
  background-color: rgba(0, 0, 0, 0.50);
}

.mode-t-tag {
  background-color: white;
  position: absolute;
  top: 8%;
  left: 13%;
  border-radius: 5px;
  border: 2px solid #333333;
  min-width: 800px;
  width: 75%;
  height: 600px;
  box-shadow: 5px 5px 6px rgba(0,0,0,0.5);
}

.mode-sm {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.50);
  min-width: 1300px;
}

.mode-sm-tag {
  background-color: white;
  position: absolute;
  top: 30%;
  left: 45%;
  width: 20%;
  border-radius: 5px;
  border: 2px solid #333333;
  height: 200px;
  box-shadow: 5px 5px 6px rgba(0,0,0,0.5);
}

.contactdbSideNav {
  width: 4.6%;
  background-color: #000;
  position: fixed;
  height:100%;
  z-index:1;
  overflow-x: hidden;
  margin-top: -41.4px;
  display: flex;
  flex-direction: column;
  align-items: center
}

.contactdbSideNavB {
  width: 4.6%;
  background-color: #000;
  position: fixed;
  height:100%;
  z-index:1;
  overflow-x: hidden;
  margin-top: -41.8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-header {
  height: 40px;
}

.calendarSideNav {
  width: 16%;
  background-color: #000;
  height:84vh;
  overflow-x: hidden;
  display:flex
}

.calendarSideNavB {
  width: 5%;
  background-color: #000;
  height:100%;
  overflow-x: hidden;
}

.react-datepicker__input-container input {
  height: 28px;
  border-radius: 3px;
  border: 1px solid #727272;
  font-size: 12px;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 8%;
}

.react-datepicker-wrapper {
  height: 28px;
}

.venue-button {
  color: #cdcdcd;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px
}
.venue-button :hover { color: #fff; }
.venue-button-select {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px
}

.calendar-button {
  color: #cdcdcd;
  margin-left: 30%;
  font-size: 24px;
  margin-top: -5px;
  cursor: pointer
}
.calendar-button :hover { color: #fff }

.venue-button2 {
  color: #cdcdcd;
  margin-left: 30%;
  font-size: 24px;
  margin-top: 30px;
  cursor: pointer;
}
.venue-button2:hover { color: #fff }

.buyer-button {
  color: #cdcdcd;
  margin-top: 10px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center
}
.buyer-button :hover { color: #fff; }
.buyer-button-select {
  color: #fff;
  margin-top: 10px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center
}

.contact-button {
  color: #cdcdcd;
  margin-top: 10px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center
}
.contact-button :hover { color: #fff }
.contact-button-select {
  color: #fff;
  margin-top: 10px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
  color: #373a3c;
  font-size: 12px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0,0,0,.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
padding: 0;
}

/* .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
} */

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.nav-button {
  color: #cdcdcd
}
.nav-button:hover { color: #fff }


/*input[type="checkbox"] {
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  display: inline-block;
  position: relative;
  top: -1px;
  width: 14px;
  height: 14px;
  margin: -1px 0px 0 0;
  vertical-align: middle;
  background: white left top no-repeat;
  border: 1px solid #ccc;
  cursor: pointer;
  color: #000;
}

input[type="checkbox"]:checked {
  background: black left top no-repeat;
  
}*/

.dropdown-item:hover {
  color: #000 !important;
  }
.dropdown-item:active {
  background-color: #fff !important
  }

.searchbar {
  width: 100px;
  height: 34px;
  background-color: #000;
  border: 2px solid #333;
  border-radius: 10px;
  color: #cdcdcd;
  font-size: 12px;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 2%;
  box-shadow: 1.5px 1.5px 1.5px 0px rgb(94, 94, 94)

}
.searchbar:focus {
  width: 250px;
  outline: none;
}

.rcw-conversation-container > .rcw-header {
  background-color: red;
}
 
.rcw-message > .rcw-response {
  background-color: black;
  color: white;
}

.fc td {
  background: #fff;
}

.fc-day {
  background: #f5f5f5;
}

.fc-event-content {
  padding: 10px
}

.react-contexify {
  font-size: 12px;
}

.example-appear {
  transform: translateX(100%);
}

.example-appear.example-appear-active {
  transform: translateX(0%);
  transition: transform 500ms ease-in-out;
}

/*:root {
  --fc-daygrid-event-dot-width: 18px;
  --fc-event-bg-color: #cdcdcd;
  --fc-event-border-color: black;
  --fc-event-border-size: 5px;
  --fc-event-text-color: black;
}*/

#shorturl {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#shorturl tr {
  background-color: #fff;
  cursor: pointer;
}

#shorturl td, #shorturl th {
  border: 1px solid #ddd;
}

#shorturl th {

  text-align: left;
  background-color: #000;
  color: white;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 50% !important;
  height: 100% !important;
}


/* DASHBOARD */

#client_tree {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#client_tree tr {
  background-color: #111;
  color: #fff;
  cursor: pointer;
}

#client_tree td, #client_tree th {
  border: 1px solid #333;
}

#client_tree tr:hover {background-color: rgb(26, 26, 26);}

.rca-modal {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.react-confirm-alert-overlay {
  position: absolute;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.144);
  display: flex;
  align-items: center;
  justify-content: center
}

.react-confirm-alert-z2 {
  background-color: #fff;
  width: auto;
  height: auto;
  border: 2px solid #333;
  border-radius: 10px;
  z-index: 5;
}

.react-confirm-alert-google {
  background-color: #000;
  width: 100%;
  height: auto;
  border-radius: 10px;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* custom alert dialog styles */
.add-dialog h3 {
  margin: 0;
}
.add-dialog-buttons {
  float: right;
}
.add-dialog-buttons button+button {
  margin-left: 0.5em;
}

/* global css */
.user-plus {
  color: #cdcdcd;
  cursor: pointer
}
.user-plus:hover { color: #000 }

.phone-input {
  width: 75% !important;
  height: 30px !important;
  border-radius: 4px !important;
  border: .4px solid #000;
}

.date-input-field {
  width: 100%;
  height: 100%;
  font-size: 12px
}

.react-datepicker__input-container  {
  width: inherit;
  height: inherit;
}
.react-datepicker__input-container input {
  width: inherit;
  height: 100%;
}
.react-datepicker-wrapper {
  width: 100%;
  height: 100%
}

.database-link {
  color: #cdcdcd;
  font-size: 22px;
}
.database-link:hover { color: #333333 }

#admin_totals {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#admin_totals tr {
  background-color: #fff;
}

#admin_totals td {
  border: 1px solid #ddd;
  font-size: 12px;
}

#admin_totals th {
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 5px;
}

#client_permissions_table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#client_permissions_table tr {
  background-color: #fff;
}

#client_permissions_table td {
  border: 1px solid #ddd;
  font-size: 12px;
  padding: 5px;
}

#client_permissions_table th {
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 5px;
  background-color: rgb(1, 22, 48);
}

.autocompleteMenu {
  /* height: 25px; */
  border: .5px solid #ddd;
  cursor: pointer;
}

.autocompleteMenu:hover { background-color: #e6f7ff; }

.attachment_table {
  border: 1px dashed gray;
  padding-left: 0.5rem;
  background-color: #f1f1f1;
  width: 100%;
}

.attachment_table_client {
  border: 1px dashed gray;
  background-color: #f1f1f1;
  width: 100%;
}

/* .attachment_table:hover { background-color: #e6f7ff; } */

.attachment_btns {
  cursor: pointer; 
  margin-top: -5px;
  color: #cdcdcd;
}

.attachment_btns:hover { color: #9c9c9c; }

.letter_cap:first-letter {
  text-transform:capitalize;
}

.custom-checkbox-root .MuiSvgIcon-root{
  width: 18px;
  height: 18px;
}

#dash-settings-contact-table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#dash-settings-contact-table tr {
  background-color: #fff;
}

#dash-settings-contact-table td, #dash-settings-contact-table th {
  border: 1px solid #ddd;
  height: 22px;
}

#dash-settings-contact-table_nb {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#dash-settings-contact-table_nb tr {
  background-color: #fff;
}
#dash-settings-contact-table_nb td, #dash-settings-contact-table_nb th {
  height: 22px;
}

.e-rte-content {
  margin: 0 0 10px;
  margin-bottom: 10px;
  line-height: .5;
}

.email-dialog-content-wrapper {
  height: 500px;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.email-dialog-table-wrapper {
  max-height: 200px;
  overflow-y: scroll;
  width: 100%;
  border: 2px solid #000
}

#email-dialog-table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
#email-dialog-table td {
  background-color: #fff;
  color: #000;
  height: 22px;
  border: 1px solid #000;
  font-size: 10px;
}
#email-dialog-table th {
  background-color: #333;
  color: #fff;
  height: 22px;
  border: 1px solid #000;
  font-size: 10px;
  position: sticky;
}

#permissions-table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#permissions-table td {
  background-color: #fff;
  color: #000;
  height: 22px;
  border: 1px solid #000;
  font-size: 10px;
}
#permissions-table th {
  background-color: #333;
  color: #fff;
  height: 22px;
  border: 1px solid #000;
  font-size: 10px;
}

.dashboard-offer-icons-exp { color: #dddcdc; }
.dashboard-offer-icons-exp:hover { color: #333; }
.dashboard-offer-icons-link { color: #dddcdc; }
.dashboard-offer-icons-link:hover { color: blue; }
.dashboard-offer-icons-email { color: #dddcdc; }
.dashboard-offer-icons-email:hover { color: green; }

.datePickerCustom {
  background-color: rgb(1, 22, 48);
  color: #fff
}

.grey_select {
  color: rgb(184, 184, 184);
}
.grey_select:hover {
  color: #333;
}

.yellow_select {
  color: rgb(236, 221, 0);
}
.yellow_select:hover {
  color: rgb(134, 125, 0);
}

.red_select {
  color: rgb(199, 0, 0);
}
.red_select:hover {
  color: rgb(99, 0, 0);
}

.custom-confirm-alert {
  z-index: 999999;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
}

.custom-toggle-menu {
  padding: 0;
  margin: 0;
  background-color: #000;
  width: 30px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid rgb(128, 128, 128);
  border-top: 2px solid rgb(128, 128, 128);
  border-bottom: .5px solid rgb(128, 128, 128);
  border-right: .5px solid rgb(128, 128, 128);
  z-index: 0;
  border-radius: 5px;
}
.custom-toggle-menu:hover { background-color: #000; border-left: 2px solid rgb(80, 80, 80); border-top: 2px solid rgb(80, 80, 80);}
.custom-toggle-menu:focus { background-color: #000; box-shadow: 0 0 0 }
.custom-toggle-menu:active { background-color: #0B6EFD; box-shadow: 0 0 0; }

.custom-toggle-menu-b {
  padding: 0;
  margin: 0;
  background-color: #ddd;
  width: 30px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid rgb(128, 128, 128);
  border-top: 2px solid rgb(128, 128, 128);
  border-bottom: .5px solid rgb(128, 128, 128);
  border-right: .5px solid rgb(128, 128, 128);
  z-index: 0;
  border-radius: 5px;
}
.custom-toggle-menu-b:hover { background-color: #ddd; border-left: 2px solid rgb(80, 80, 80); border-top: 2px solid rgb(80, 80, 80);}
.custom-toggle-menu-b:focus { background-color: #ddd; box-shadow: 0 0 0 }
.custom-toggle-menu-b:active { background-color: #0B6EFD; box-shadow: 0 0 0; }

.custom-toggle-menu-icon {
  color: #cdcdcd;
  font-size: 20px;
  z-index: 2;
}

.custom-toggle-menu-auto {
  padding: 15;
  margin: 0;
  background-color: #000;
  width: auto;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid rgb(128, 128, 128);
  border-top: 2px solid rgb(128, 128, 128);
  border-bottom: .5px solid rgb(128, 128, 128);
  border-right: .5px solid rgb(128, 128, 128);
  z-index: 0;
  border-radius: 5px;
}
.custom-toggle-menu-auto:hover { background-color: #000; border-left: 2px solid rgb(80, 80, 80); border-top: 2px solid rgb(80, 80, 80);}
.custom-toggle-menu-auto:focus { background-color: #000; box-shadow: 0 0 0 }
.custom-toggle-menu-auto:active { background-color: #0B6EFD; box-shadow: 0 0 0; }

.db-menu-select {
  background-color: #111;
  width: 220px;
  height: 28px;
  color: #fff;
  font-weight: 'bold';
  font-size: 14px;
  border-left: 2px solid rgb(128, 128, 128);
  border-top: 2px solid rgb(128, 128, 128);
  border-bottom: .5px solid rgb(128, 128, 128);
  border-right: .5px solid rgb(128, 128, 128);
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}
.db-menu-select:hover { background-color: #111; border-left: 2px solid rgb(80, 80, 80); border-top: 2px solid rgb(80, 80, 80); outline: none }
.db-menu-select:focus { background-color: #111; box-shadow: 0 0 0; border-left: 2px solid rgb(80, 80, 80); border-top: 2px solid rgb(80, 80, 80); outline: none }
.db-menu-select:active { background-color: #111; box-shadow: 0 0 0; border-left: 2px solid rgb(80, 80, 80); border-top: 2px solid rgb(80, 80, 80); outline: none }


